import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				HAVEN RETREAT
			</title>
			<meta name={"description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:title"} content={"HAVEN RETREAT"} />
			<meta property={"og:description"} content={"У Haven Retreat ми створили місце, де розкіш і природа поєднуються. Наші ексклюзивні спа-програми та релаксаційні процедури дозволять вам зануритися у стан спокою та насолоди. Відновіть енергію тіла та душі в атмосфері бездоганної гармонії та затишку."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed228be778710018d371a1/images/bigstock-Spa-Accessories-99070442.webp?v=2024-09-20T10:47:40.772Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Header23>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66ed228be778710018d371a1/images/HOTELSPAWISA.jpg?v=2024-09-20T10:47:40.780Z) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				ПОСЛУГИ
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--base"
				color="rgba(255, 255, 255, 0.58)"
				width="700px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				Зануртеся у світ різноманітних послуг Haven Retreat, де кожна процедура спрямована на відновлення вашого тіла і душі. Ми створили програму, яка включає як класичні, так і сучасні терапії, розроблені для покращення вашого фізичного та емоційного стану.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Повні спа-процедури
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Ароматерапевтичний масаж: Використання ефірних олій для покращення фізичного та емоційного стану.
Шведський масаж: Знімає м’язову напругу та сприяє глибокому розслабленню через м’які рухи.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Термальні та інфрачервоні сауни
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Інфрачервона сауна: Глибокий вплив тепла для детоксикації та розслаблення м’язів.
Парова сауна: Підвищує кровообіг, покращує здоров'я шкіри та дихальної системи.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Індивідуальні масажні процедури
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Масаж глибоких тканин: Знімає глибоку м’язову напругу та полегшує біль.
Терапія гарячим камінням: Використання нагрітого каміння для розслаблення та зняття стресу.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Медитація та йога
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Проведення медитаційних сесій з інструкторами, які допоможуть досягти внутрішньої гармонії.
Майстер-класи з йоги: Для всіх рівнів, включаючи початківців та досвідчених практиків.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Детокс та програми харчування
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Індивідуальні програми детоксикації: Розроблені відповідно до ваших індивідуальних потреб.
Консультації з харчування: Персоналізовані поради щодо оптимізації дієти та здорового харчування.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Дізнайтесь більше про наші послуги, зв’язавшись з нами або відвідавши Haven Retreat. Кожна процедура спрямована на покращення вашого здоров’я та благополуччя.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light" sm-margin="0px 0px 50px 0px">
				Ми зібрали найпоширеніші запитання, щоб зробити ваше відвідування Haven Retreat максимально зручним та інформованим. Наша мета — забезпечити ваше повне задоволення на кожному етапі.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Що потрібно взяти з собою?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми рекомендуємо взяти з собою зручний одяг і купальник, якщо ви плануєте відвідування сауни або басейну. Ми надаємо всі інші необхідні аксесуари, такі як халати та капці.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як рано потрібно прибути?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Рекомендуємо прибути на 15-20 хвилин раніше, щоб мати час розслабитися перед процедурою та насолодитися додатковими зручностями.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можу я вибрати свого терапевта?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, під час бронювання ви можете запросити конкретного терапевта, якщо він доступний.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи доступні у вас програми для постійних клієнтів?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми пропонуємо різні пакети та програми лояльності. Для отримання додаткової інформації зв'яжіться з нами.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи доступний заклад для людей з обмеженими можливостями?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, Haven Retreat обладнаний усіма необхідними умовами для людей з обмеженими можливостями.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer123 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});